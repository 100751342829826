<template>
	<div :class="themeClass" class="couponCont width-fill margin-t-10 flex-column">
		<div class="coupon-Box">
			<div class="coupon-title">抽奖券</div>
			<el-tabs v-model="activeName" @tab-click="handleClick" class="coupon-tab">
				<el-tab-pane label="未使用" name="first" v-if="usedList != null">
					<!-- 店铺 -->
					<div v-if="unusedList.items && unusedList.items.length < 1" class="flex-column-center-center  accountCont margin-t-15">
						<svg-icon icon-class="NoContent" />
						<!-- <div>暂无内容</div> -->
					</div>
					<div v-else class="accountCont width-fill margin-t-15">
						<div class="lottery lottery-bgimg1" v-for="(item,index) of unusedList.fActivity" :key="index" >
							<div class="lotteryLeft">
								<div class="lotteryLeft-top" style="border-bottom: 1px dashed #009966;">
									<!-- <div style="font-size: 14px;color:#000;">
										{{item.fAwardPoolName}}
									</div> -->
									<div style="font-size: 26px;color:#009966;font-weight:700;">
										{{item.fAwardPoolName}}
									</div>
									<div style="font-size: 13px;color:#009966;margin-top:8px;margin-bottom:5px;">
										{{item.fBeginTime | dateFormat}}至{{item.fEndTime | dateFormat}}
									</div>
								</div>
								<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
									<el-dropdown style="width: 100%;position: relative;" size="small">
									  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
									    <p style="display: inline-block;color:#009966; font-size:14px;">使用规则</p><i class="iconfont icon-zhankai1" style="display: inline-block; float: right; color:#009966;margin-top: 3px;"></i>
									  </span>
									  <el-dropdown-menu slot="dropdown" style="width: 220px;">
										<el-dropdown-item v-for="(items,indexs) of item.fRuleList" :key="indexs">{{indexs+1}}.{{items.fDescribe}}</el-dropdown-item>
									  </el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
							<div class="lotteryRight" @click="goDrawLottery(item)" >
								<div style="padding-top: 28px;">立<br>即<br>使<br>用</div>
							</div>
						</div>
					<!-- </div> -->
					<!-- 权益 -->
					<!-- <div class="accountCont width-fill margin-t-15"> -->
						<div class="lottery lottery-bgimg3" v-for="(item,index) of unusedList.fPlatform" :key="index" >
							<div class="lotteryLeft">
								<div class="lotteryLeft-top" style="border-bottom: 1px dashed #333;">
									<!-- <div style="font-size: 14px;color:#000;">
										{{item.fAwardPoolName}}
									</div> -->
									<!-- <div style="font-size: 26px;color:#333;font-weight:700;">
										{{item.fAwardPoolName }}
									</div> -->
									<div style="font-size: 26px;color:#333;font-weight:700;">
										会员权益抽奖券
									</div>
									<div style="font-size: 13px;color:#333;margin-top:8px;margin-bottom:5px;">
										{{item.fBeginTime | dateFormat}}至{{item.fEndTime | dateFormat}}
									</div>
								</div>
								<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
									<el-dropdown style="width: 100%;position: relative;" size="small">
									  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
									    <p style="display: inline-block;color:#333; font-size:14px;">使用规则</p><i class="iconfont icon-zhankai1" style="display: inline-block; float: right; color:#333;margin-top: 3px;"></i>
									  </span>
									  <el-dropdown-menu slot="dropdown" style="width: 220px;">
										<el-dropdown-item v-for="(items,indexs) of item.fRuleList" :key="indexs">{{indexs+1}}.{{items.fDescribe}}</el-dropdown-item>
									  </el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
							<div class="lotteryRight1"  @click="goDrawLottery(item)" >
								<div style="padding-top: 28px;">立<br>即<br>使<br>用</div>
							</div>
						</div>
					</div>
					<pagination :total="total" :currentPage="page" :pagesize="maxResultCount" @handleCurrentChange="select" />
				</el-tab-pane>
				<el-tab-pane label="已使用" name="second" v-if="usedList != null">
					<div v-if="usedList.items && usedList.items.length < 1" class="flex-column-center-center  accountCont margin-t-15">
						<svg-icon icon-class="NoContent" />
					</div>
					<!-- 店铺 -->
					<div v-else class="accountCont width-fill margin-t-15">
						<div class="lottery lottery-bgimg2" v-for="(item,index) of usedList.fActivity" :key="index">
							<div class="lotteryLeft">
								<div class="lotteryLeft-top" style="border-bottom: 1px dashed #999;">
									<!-- <div style="font-size: 14px;color:#000;">
										{{item.fAwardPoolName}}
									</div> -->
									<div style="font-size: 26px;color:#999;font-weight:700;">
										{{item.fAwardPoolName}}
									</div>
									<div style="font-size: 13px;color:#999;margin-top:8px;margin-bottom:5px;">
										{{item.fBeginTime | dateFormat}}至{{item.fEndTime | dateFormat}}
									</div>
								</div>
								<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
									<el-dropdown style="width: 100%;position: relative;" size="small">
									  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
									    <p style="display: inline-block;color:#999; font-size:14px;">使用规则</p><i class="iconfont icon-zhankai1" style="display: inline-block; float: right; color:#999;margin-top: 3px;"></i>
									  </span>
									  
									  <el-dropdown-menu slot="dropdown" style="width: 220px;">
									  	<el-dropdown-item v-for="(items,indexs) of item.fRuleList" :key="indexs">{{indexs+1}}.{{items.fDescribe}}</el-dropdown-item>
									  </el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
							<div class="lotteryRight">
								<div style="padding-top: 40px;">已<br>使<br>用</div>
							</div>
						</div>
					<!-- </div> -->
					<!-- 权益 -->
					<!-- <div class="accountCont width-fill margin-t-15"> -->
						<div class="lottery lottery-bgimg4" v-for="(item,index) of usedList.fPlatform" :key="index">
							<div class="lotteryLeft">
								<div class="lotteryLeft-top" style="border-bottom: 1px dashed #999;">
									<!-- <div style="font-size: 14px;color:#000;">
										{{item.fAwardPoolName}}
									</div> -->
									<!-- <div style="font-size: 26px;color:#999;font-weight:700;">
										{{item.fAwardPoolName}}
									</div> -->
									<div style="font-size: 26px;color:#999;font-weight:700;">
										会员权益抽奖券
									</div>
									<div style="font-size: 13px;color:#999;margin-top:8px;margin-bottom:5px;">
										{{item.fBeginTime | dateFormat}}至{{item.fEndTime | dateFormat}}
									</div>
								</div>
								<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
									<el-dropdown style="width: 100%;position: relative;" size="small">
									  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
									    <p style="display: inline-block;color:#999; font-size:14px;">使用规则</p><i class="iconfont icon-zhankai1" style="display: inline-block; float: right; color:#999;margin-top: 3px;"></i>
									  </span>
									  
									  <el-dropdown-menu slot="dropdown" style="width: 220px;">
									  	<el-dropdown-item v-for="(items,indexs) of item.fRuleList" :key="indexs">{{indexs+1}}.{{items.fDescribe}}</el-dropdown-item>
									  </el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
							<div class="lotteryRight1">
								<div style="padding-top: 40px;">已<br>使<br>用</div>
							</div>
						</div>
					</div>
					<pagination :total="total1" :currentPage="page1" :pagesize="maxResultCount1" @handleCurrentChange="select1" />
				</el-tab-pane>
				<el-tab-pane label="已失效" name="third" v-if="usedList != null">
					<div v-if="expireList.items &&  expireList.items.length < 1" class="flex-column-center-center accountCont margin-t-15">
						<svg-icon icon-class="NoContent" />
					</div>
					<!-- 店铺 -->
					<div v-else class="accountCont width-fill margin-t-15">
						<div class="lottery lottery-bgimg2" v-for="(item,index) of expireList.fActivity" :key="index">
							<div class="lotteryLeft">
								<div class="lotteryLeft-top" style="border-bottom: 1px dashed #999;">
									<!-- <div style="font-size: 14px;color:#000;">
										{{item.fAwardPoolName}}
									</div> -->
									<div style="font-size: 26px;color:#999;font-weight:700;">
										{{item.fAwardPoolName}}
									</div>
									<div style="font-size: 13px;color:#999;margin-top:8px;margin-bottom:5px;">
										{{item.fBeginTime | dateFormat}}至{{item.fEndTime | dateFormat}}
									</div>
								</div>
								<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
									<el-dropdown style="width: 100%;position: relative;" size="small">
									  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
									    <p style="display: inline-block;color:#999; font-size:14px;">使用规则</p><i class="iconfont icon-zhankai1" style="display: inline-block; float: right; color:#999;margin-top: 3px;"></i>
									  </span>
									  
									  <el-dropdown-menu slot="dropdown" style="width: 220px;">
									  	<el-dropdown-item v-for="(items,indexs) of item.fRuleList" :key="indexs">{{indexs+1}}.{{items.fDescribe}}</el-dropdown-item>
									  </el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
							<div class="lotteryRight">
								<div style="padding-top: 40px;">已<br>失<br>效</div>
							</div>
						</div>
					<!-- </div> -->
					<!-- 平台 -->
					<!-- <div class="accountCont width-fill margin-t-15"> -->
						<div class="lottery lottery-bgimg4" v-for="(item,index) of expireList.fPlatform" :key="index">
							<div class="lotteryLeft">
								<div class="lotteryLeft-top" style="border-bottom: 1px dashed #999;">
									<!-- <div style="font-size: 14px;color:#000;">
										{{item.fAwardPoolName}}
									</div> -->
									<!-- <div style="font-size: 26px;color:#999;font-weight:700;">
										{{item.fAwardPoolName}}
									</div> -->
									<div style="font-size: 26px;color:#999;font-weight:700;">
										会员权益抽奖券
									</div>
									<div style="font-size: 13px;color:#999;margin-top:8px;margin-bottom:5px;">
										{{item.fBeginTime | dateFormat}}至{{item.fEndTime | dateFormat}}
									</div>
								</div>
								<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
									<el-dropdown style="width: 100%;position: relative;" size="small">
									  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
									    <p style="display: inline-block;color:#999; font-size:14px;">使用规则</p><i class="iconfont icon-zhankai1" style="display: inline-block; float: right; color:#999;margin-top: 3px;"></i>
									  </span>
									  
									  <el-dropdown-menu slot="dropdown" style="width: 220px;">
									  	<el-dropdown-item v-for="(items,indexs) of item.fRuleList" :key="indexs">{{indexs+1}}.{{items.fDescribe}}</el-dropdown-item>
									  </el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
							<div class="lotteryRight1">
								<div style="padding-top: 40px;">已<br>失<br>效</div>
							</div>
						</div>
					</div>
					<pagination :total="total2" :currentPage="page2" :pagesize="maxResultCount2" @handleCurrentChange="select2" />
				</el-tab-pane>

			</el-tabs>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from '@/components/pagination/pagination';
	export default {
		components: {
			pagination,
		},
		data() {
			return {
				total: 0, // 条数
				page: 1, // 页数
				maxResultCount: 12,
				total1: 0, // 条数
				page1: 1, // 页数
				maxResultCount1: 12,
				total2: 0, // 条数
				page2: 1, // 页数
				maxResultCount2: 12,
				lotteryList: [
					{
						activityName: '抽奖活动',
						activityTitle: '金币抽奖券',
						activityTermOfValidity: '2020-10-31至2020-10-31',
						useType: 1,
						useRules: [{
								tip: '本优惠券仅用于购买昌吉天山水泥时使用',
							},
							{
								tip: '有效期内只可使用一次',
							},
							{
								tip: '一笔订单只能使用一次优惠，且不与其他优惠同享'
							}
						]
					},
					{
						activityName: '抽奖活动',
						activityTitle: '金币抽奖券',
						activityTermOfValidity: '2020-10-31至2020-10-31',
						useType: 1,
						useRules: [{
								tip: '本优惠券仅用于购买昌吉天山水泥时使用',
							},
							{
								tip: '有效期内只可使用一次',
							},
							{
								tip: '一笔订单只能使用一次优惠，且不与其他优惠同享'
							}
						]
					},
					{
						activityName: '抽奖活动',
						activityTitle: '金币抽奖券',
						activityTermOfValidity: '2020-10-31至2020-10-31',
						useType: 1,
						useRules: [{
								tip: '本优惠券仅用于购买昌吉天山水泥时使用',
							},
							{
								tip: '有效期内只可使用一次',
							},
							{
								tip: '一笔订单只能使用一次优惠，且不与其他优惠同享'
							}
						]
					},
					{
						activityName: '抽奖活动',
						activityTitle: '金币抽奖券',
						activityTermOfValidity: '2020-10-31至2020-10-31',
						useType: 1,
						useRules: [{
								tip: '本优惠券仅用于购买昌吉天山水泥时使用',
							},
							{
								tip: '有效期内只可使用一次',
							},
							{
								tip: '一笔订单只能使用一次优惠，且不与其他优惠同享'
							}
						]
					}
				],
				activeName: 'first',
				unusedList:{},
				usedList:{},
				expireList:{},
			};  
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		created(){
			this.getUnusedList()
		},
		methods: {
			select(page) {
			    this.page = page;
			    this.getUnusedList()
			},
			select1(page) {
			    this.page1 = page;
			    this.getUsedList()
			},
			select2(page) {
			    this.page2 = page;
			    this.getExpireList()
			},
			// 立即使用
			goDrawLottery(item) {
				console.log(item)
				if (item.fShopID == -1) {
					this.$router.push({
						name: 'luckMainPlatform',
						query: {
							// shopId: this.fShopUnitID,
							shopId: '-1'
						}
					});
				} else {
					this.$router.push({
						name: 'luckMain',
						query: {
							shopId: item.fShopID
						}
					});
				}
			},
			// 立即使用点击事件
			useClick(id){
				//跳转商品列表
				console.log(id);
				this.$router.push({
					path: '/Goods',
					query: {},
				});
			},
			// 未使用抽奖券
			getUnusedList() {
				this.ApiRequestPost('api/mall/ebactivity/gift-record/get-customer-not-used', {
					maxResultCount: this.maxResultCount,
					skipCount: (this.page - 1) * this.maxResultCount
				}).then(res => {
					console.log('优惠券')
					let fActivity = []
					let fPlatform = []
					res.obj.items.forEach(item => {
						if(item.fIsActivity == 1) {
							fActivity.push(item)
						} else {
							fPlatform.push(item)
						}
					})
					this.total = res.obj.totalCount
					this.unusedList=res.obj
					this.unusedList.fActivity = fActivity
					this.unusedList.fPlatform = fPlatform
					console.log('unusedList',this.unusedList);
				}).catch(err => {
					
				})
			},
			// 已使用优惠券
			getUsedList() {
				this.ApiRequestPost('api/mall/ebactivity/gift-record/get-customer-invalid', {
					maxResultCount: this.maxResultCount1,
					skipCount: (this.page1 - 1) * this.maxResultCount1
				}).then(res => {
					let fActivity = []
					let fPlatform = []
					res.obj.items.forEach(item => {
						if(item.fIsActivity == 1) {
							fActivity.push(item)
						} else {
							fPlatform.push(item)
						}
					})
					this.total1 = res.obj.totalCount
					this.usedList=res.obj
					this.usedList.fActivity = fActivity
					this.usedList.fPlatform = fPlatform
					console.log('usedList',this.usedList);
				}).catch(err => {
					
				})
			},
			// 已失效优惠券
			getExpireList() {
				this.ApiRequestPost('api/mall/ebactivity/gift-record/get-customer-expire', {
					maxResultCount: this.maxResultCount2,
					skipCount: (this.page2 - 1) * this.maxResultCount2
				}).then(res => {
					let fActivity = []
					let fPlatform = []
					res.obj.items.forEach(item => {
						if(item.fIsActivity == 1) {
							fActivity.push(item)
						} else {
							fPlatform.push(item)
						}
					})
					this.total2 = res.obj.totalCount
					this.expireList=res.obj
					this.expireList.fActivity = fActivity
					this.expireList.fPlatform = fPlatform
					console.log('expireList',this.expireList);
				}).catch(err => {
					
				})
			},
			
			handleClick(val) {
				// console.log(val.name);
				if(val.name=='first'){
					this.getUnusedList()
				}
				if(val.name=='second'){
					this.getUsedList()
				}
				if(val.name=="third"){
					this.getExpireList()
				}
			},
			slideBtn(item) {
				this.$forceUpdate()
			},

		}
	};
</script>
<style lang="scss" scoped="scoped">
	.right-top-name {
		height: 18px;
		line-height: 18px;
	}
	::v-deep .el-tabs__item.is-active {
		@include themify($themes) {
			color: themed("themes_color") !important;
		}
	}
	::v-deep .el-tabs__item{
		width: 60px;
		padding: 0px;
		text-align: center;
	}

	::v-deep .el-tabs__active-bar {
		@include themify($themes) {
			background: themed("themes_color") !important;
		}
		height: 1px !important;
	}

	::v-deep .el-tabs__nav .el-tabs__item:hover {
		@include themify($themes) {
			color: themed("themes_color") !important;
		}
	}

	.tips-card {
		width: 702px;
		height: 674px;
		margin: -300px 26px 20px 28px;
		/* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); */
	}

	.dot-box {
		width: 12rpx;
		height: 12rpx;
	}

	#cuopon-btn {
		width: 110px;
		height: 30px;
		background-color: rgba(1, 115, 254, 1);
		box-sizing: border-box;
		box-shadow: none;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.coupon-Box {
		/* width: 917px; */
		height: 100%;
		/* background-color: #000; */
		/* 	margin-left: 450px; */
		position: relative;
	}

	.coupon-title {
		font-size: 18px;
		font-weight: bold;
		color: #000000;
	}

	.coupon-btn {
		position: absolute;
		top: 30px;
		right: 0px;
		left: 760px;
		width: 160px;
		height: 40px;
		background: #0173fe;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
	}

	::v-deep .el-tabs__nav-wrap::after {
		display: none;
	}

	::v-deep .el-tabs__header {
		margin: 0 0 0px;
	}

	.comBtn {
		width: 110px;
		height: 30px;
		line-height: 30px;
		cursor: pointer;
	}

	.couponCont {
		height: 100%;
		box-sizing: border-box;
		max-width: 1100px;
		background-color: #fff;
		padding: 10px 10px;
	}

	.coupList {
		width: calc(33.3%-12px);
		width: -webkit-calc(33.3% - 12px);
		margin-right: 18px;
		margin-bottom: 18px;
		min-height: 123px;
		float: left;
		word-break: break-all;
		z-index: 1;

	}

	.coupList:nth-child(3n) {
		margin-right: 0px;
	}

	.coupon-con {
		background: #ffffff;
		border-radius: 10px;
		box-shadow: 0px 0px 10px 0px rgba(13, 13, 13, 0.176470588235294);
		margin-left: 3px;
	}

	.collectCoupons-card1 {
		width: 100%;
		height: 94px;
		/* background-color: #FC503A; */
		color: #ffffff;
		display: flex;
		flex-direction: row;
		border-top-left-radius: 10px;
		background-image: linear-gradient(to bottom right, #FA8A77, #FC3720);
	}

	.collectCoupons-card1-none {
		width: 100%;
		height: 94px;
		/* background-color: #FC503A; */
		color: #ffffff;
		display: flex;
		flex-direction: row;
		border-top-left-radius: 10px;
		background-image: linear-gradient(to bottom right, #E0E0E1, #A7ACBB);
	}

	.collectCoupons-card2 {
		font-size: 20px;
		font-size: 20px;
		color: #ffffff;
	}

	.collectCoupons-card-left {
		width: 28%;
	}

	.collectCoupons-card3 {
		font-weight: 700;
		font-size: 24px;
		line-height: 40px;
		color: #ffffff;
	}

	.collectCoupons-card4 {
		width: 100%;
		height: 29px;
		border-bottom-left-radius: 10px;
		font-size: 12px;
		font-weight: 400;
		color: #cc3300;
		line-height: 29px;
		background-image: linear-gradient(to right, #FFCBB8, #FF9884);
		border-bottom-left-radius: 10px;

	}

	.collectCoupons-card4-none1 {
		width: 100%;
		height: 29px;
		border-bottom-left-radius: 10px;
		font-size: 12px;
		font-weight: 400;
		color: #999999;
		line-height: 29px;
		border-bottom-left-radius: 10px;
		background-color: #F2F2F2;
	}

	.collectCoupons-card6 {
		position: absolute;
		color: #000000;
		font-weight: 700;
		font-size: 14px;
		z-index: 1 !important;
	}

	.collectCoupons-card6-none2 {
		font-weight: 700;
		font-style: normal;
		color: #999999;
		font-size: 14px;
	}

	.collectCoupons-card7 {
		font-size: 12px;
		color: #999999;
		position: absolute;
		margin-top: 25px;
	}

	.collectCoupons-card8 {
		width: 63px;
		height: 18px;
		line-height: 18px;
		float: right;
		margin-top: 47px;
		cursor: pointer;
	}
	.collectCoupons-card29{
		float: left;
		width: 120px;
		height: 18px;
		margin-top: 47px;
		font-weight: 400;
	}

	.collectCoupons-card9 {
		display: flex;
		justify-content: space-between;
		border-top: 1px dashed #cccccc;
		padding-top: 5px;
	}

	.collectCoupons-card9 p {
		font-size: 12px;
		color: #999999;
		line-height: 18px;
	}

	.collectCoupons-card5 {
		height: 94px;
		padding-top: 13px;
		box-sizing: border-box;
	}

	.left-bottom {
		height: 58rpx;
		color: #000000;
		line-height: 58rpx;
		border-bottom-left-radius: 20rpx;
		background-color: #F2F2F2;
		color: #999999;
		;
	}

	.collectCoupons-card-right {
		width: 75%;
		padding: 0 10px;
		padding-left: 13px;
		position: relative;
		box-sizing: border-box;
	}

	.stateUse {
		position: absolute;
		top: 3px;
		right: 1px;
		font-size: 29px;
		z-index: 0;
	}

	body {
		position: relative;
	}
	.lottery {
		width: 285px;
		height: 130px;
		// background: pink;
		border-radius: 10px;
		box-shadow: 0px 0px 10px rgba(13, 13, 13, 0.176470588235294);
		display: inline-block;
		margin-right: 20px;
		margin-top: 10px;
		.lotteryLeft {
			width: 230px;
			height: 130px;
			float: left;
			padding: 14px 17px 0 17px;
			box-sizing: border-box;
			border-radius: 10px 0 0 10px;
			.lotteryLeft-top {
				
			}
		}
		.lotteryRight {
			width: 55px;
			height: 130px;
			background: linear-gradient(180deg, rgba(235, 235, 235, 1) 0%, rgba(235, 235, 235, 1) 0%, rgba(255, 255, 255, 1) 45%, rgba(215, 222, 226, 1) 100%, rgba(215, 222, 226, 1) 100%);
			float: right;
			font-size: 14px;
			color: #666666;
			text-align: center;
			border-radius: 0 10px 10px 0;
		}
		.lotteryRight1 {
			width: 55px;
			height: 130px;
			background: linear-gradient(135deg, rgba(254, 245, 166, 1) 0%, rgba(254, 245, 166, 1) 0%, rgba(244, 235, 187, 1) 37%, rgba(210, 174, 88, 1) 100%, rgba(210, 174, 88, 1) 100%);
			float: right;
			font-size: 14px;
			color: #666666;
			text-align: center;
			border-radius: 0 10px 10px 0;
		}
	}
	.lottery-bgimg1 {
		background: url('../../assets/lottery-1.png') no-repeat;
		background-size: 100% 100%;
	}
	.lottery-bgimg2 {
		background: url('../../assets/lottery-2.png') no-repeat;
		background-size: 100% 100%;
	}
	.lottery-bgimg3 {
		background: url('../../assets/lottery-3.png') no-repeat;
		background-size: 100% 100%;
	}
	.lottery-bgimg4 {
		background: url('../../assets/lottery-4.png') no-repeat;
		background-size: 100% 100%;
	}
	.lottery:nth-child(3n) {
		margin-right: 0px;
	}
	.accountCont {
		height: 576px;
	}
</style>
